import Vue from "vue";
import VueRouter from "vue-router"
//2.使用路由
Vue.use(VueRouter);
//3.创建VueRouter的实例
const router=new VueRouter({
    //4.配置路由的path和组件
    routes:[
        //配置路由的路径
        {
            path: "/",
            name:'home',
            component: () => import("../views/home.vue"),
            redirect: '/homeFs',  // 重定向到首页
            children: [
                {
                    path: "/homeFs",
                    name:'homeFs',
                    component: () => import("../views/homeFs.vue"),
                },
                {
                    path: "/medicine",
                    name:'medicine',
                    component: () => import("../views/medicine.vue"),
                },
                {
                    path: "/contactUs",
                    name:'contactUs',
                    component: () => import("../views/contactUs.vue"),
                },
                {
                    path: "/consult",
                    name:'consult',
                    component: () => import("../views/consult.vue"),
                },
            ],
        },
    ]
})
// router.beforeEach((to,from,next) => {
//     console.log(to.path,from,next)
//     if(to.path === '/'){
//       next('/home')
//     }
// })
//5.导入路由实例
export default router